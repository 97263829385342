import { MenuEntry } from '@pancakeswap-libs/uikit'
import { getFesBNBStakingAddress, getFesBNBAddress } from 'utils/addressHelpers'

const config: MenuEntry[] = [
  {
    label: 'Home / Staking',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Contracts / Charts',
    icon: 'InfoIcon',
    items: [
      {
        label: 'CITADEL Contract',
        href: `https://etherscan.com/address/${getFesBNBAddress()}#code`,
      },
      {
        label: 'CITADEL Chart',
        href: `https://www.dextools.io/app/en/ether/pair-explorer/0x41A683248bB031e471A9c81062D8f24a580cAe4d`,
      },
      {
        label: 'CITADEL Staking Contract',
        href: `https://etherscan.com/address/${getFesBNBStakingAddress()}#code`,
      },
    ],
  },
  {
    label: 'Learn More',
    icon: 'RoadmapIcon',
    items: [
      {
        label: 'Website',
        href: 'https://www.thecitadeltoken.io/',
      },
    ],
  },
  {
    label: 'Audit',
    icon: 'AuditIcon',
    items: [
    ],
  },
]

export default config
