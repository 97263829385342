export default {
  fesbnb:{
    97:'0x6caDf6ABBCeb53e631C288778DAAcF125481C1BB',
    56:'0x6caDf6ABBCeb53e631C288778DAAcF125481C1BB',
    1:'0x6caDf6ABBCeb53e631C288778DAAcF125481C1BB',
  },
  fesbnblp:{
    97:'0x41A683248bB031e471A9c81062D8f24a580cAe4d',
    56:'0x41A683248bB031e471A9c81062D8f24a580cAe4d',
    1: '0x41A683248bB031e471A9c81062D8f24a580cAe4d',
  },
  fesbnbstaking:{
    97:'0x58523e31BDB2119d3CD5B7b96A03DD5DD7b21ad3',
    56:'0x58523e31BDB2119d3CD5B7b96A03DD5DD7b21ad3',
    1: '0x58523e31BDB2119d3CD5B7b96A03DD5DD7b21ad3',
  },
  fesbnbstakingrewards:{
    97:'0xc67B5602E820405776980CEE045ea5e4bc5151b3',
    56:'0xc67B5602E820405776980CEE045ea5e4bc5151b3',
    1: '0xc67B5602E820405776980CEE045ea5e4bc5151b3',
  },
  bnbbusdlp:{
    97:'0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16', // bnbbusd lp for calculating price
    56:'0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
    1: '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc'
  },
  cake: {
    97: '0x6caDf6ABBCeb53e631C288778DAAcF125481C1BB', // Same as fesbnb to ensure all internal calculations work
    56: '0x6caDf6ABBCeb53e631C288778DAAcF125481C1BB',
    1: '0x6caDf6ABBCeb53e631C288778DAAcF125481C1BB',
  },
  pancake: {
    97: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', 
    56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  },
  ada: {
    97: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
    56: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
  },
  xrp: {
    97: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE', 
    56: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
  },
  dot: {
    97: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402', 
    56: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
  },
  thegrailnfts:{
    97: '0x420C845C207D5cbEd4CbBB95b6E53342c451D427',
    56: '0x420C845C207D5cbEd4CbBB95b6E53342c451D427',
  },
  kotrtnfts:{
    97: '0xfffcb1Dbd1733B66D04F3de72AA65583a4dD50A6',
    56: '0xfffcb1Dbd1733B66D04F3de72AA65583a4dD50A6',
  },
  kdfnnfts:{
    97: '0xf812C8D2433B110d9bec52c3425fA90f1bD76d47', // Prod NFTs
    56: '0xf812C8D2433B110d9bec52c3425fA90f1bD76d47',
  },
  kdfnnftsbeta:{
    97: '0xfA0f28c684C2F19dA7de2b065d35a367cABF8500', // Beta NFTs
    56: '0xfA0f28c684C2F19dA7de2b065d35a367cABF8500',
  },
  milfnfts:{
    97: '0xf4c41591aca6c009cf5a9756f79f58f699c956dd', // Milf NFTs
    56: '0xf4c41591aca6c009cf5a9756f79f58f699c956dd',
  },
  kingmaker:{
    97:'0x06788E15d0755046Aa1580ed27c0fa2840897BA2',
    56:'0x06788E15d0755046Aa1580ed27c0fa2840897BA2'
  },
  donateaddress:{
    97: '0xb017c481575318017DC2122b59759B25d21f6721',
    56: '0xb017c481575318017DC2122b59759B25d21f6721',
  },
  battlefield: {
    97: '0x8c0A7C89C46d1793b70BC197B69324408c78eBA8',
    56: '0x8c0A7C89C46d1793b70BC197B69324408c78eBA8',
  },
  knight: {
    97: '0x16C0e0936E1B38Ff1F9b8a1e75d8ba29aDf87d30',
    56: '0x16C0e0936E1B38Ff1F9b8a1e75d8ba29aDf87d30',
    1: '0xFddF563F70334e00FC812F36ebCa39F82CBb44CB',
  },
  syrup: {
    97: '0xB02cD9CEBD3c9a5e1EFAe855Acb991B3143C021c', // Same as HONOR to ensure all internal calculations work
    56: '0xB02cD9CEBD3c9a5e1EFAe855Acb991B3143C021c',
  },
  honor: {
    97: '0x43636962CdF1D26E0de4BB0A94a6CDB549375D47',
    56: '0x43636962CdF1D26E0de4BB0A94a6CDB549375D47',
  },
  table: {
    97: '0xf5a2f7418035ce76967f515f39d65719bb0453b6',
    56: '0xf5a2f7418035ce76967f515f39d65719bb0453b6',
  },
  legend: {
    97: '0xDc661984735b535210CB1f52f86cc58616024192',
    56: '0xDc661984735b535210CB1f52f86cc58616024192',
  },
  squire: {
    97: '0x2F0D21f1B84F03fB9D60004fc206C86Be6902a32',
    56: '0x2F0D21f1B84F03fB9D60004fc206C86Be6902a32',
  },
  shilling: {
    97: '0x643B6ef6306417A0b3FA2813eb5BAf30F5dd8736',
    56: '0x643B6ef6306417A0b3FA2813eb5BAf30F5dd8736',
  },
  psi: {
    97: '0x9A5d9c681Db43D9863e9279c800A39449B7e1d6f',
    56: '0x9A5d9c681Db43D9863e9279c800A39449B7e1d6f',
  },
  rbt: {
    97: '0x7595bb34bf391c3de0a6878a36d06223b2bfd404',
    56: '0x7595bb34bf391c3de0a6878a36d06223b2bfd404',
  },
  masterChef: {
    97: '0x0E9b7E13a543b7F1651A6BB03a47900515eBceCf',
    56: '0x0E9b7E13a543b7F1651A6BB03a47900515eBceCf',
  },
  sousChef: {
    97: '0x3D9D08C118202A679a212FA3164d71F655efA569',
    56: '0x3D9D08C118202A679a212FA3164d71F655efA569',
  },
  wbnb: {
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
  },
  lottery: {
    97: '0x0480ac4c3B35cF3cd484cC481Ac67e26172dA15D',
    56: '0x0480ac4c3B35cF3cd484cC481Ac67e26172dA15D', 
  },
  lotteryNFT: {
    97: '0x454960BEdf09365950BE02B3987a0edA15D9B769',
    56: '0x454960BEdf09365950BE02B3987a0edA15D9B769', 
  },
  mulltiCall: {
    56: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
    97: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
    1: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
  ust: {
    56: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
    97: '',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a', // We don't use it
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A', // We don't use it
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07', // We don't use it
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E', // We don't use it
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf', // We don't use it
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148', // We don't use it
  },
  eth: {
    56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
    97: '',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149', // We don't use it
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a', // We don't use it
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3', // We don't use it
  },
}
